import React from 'react';
import aboutUsImage from '../assets/img/about_us/about_us_1.jpg';
import aboutUsSVG from '../assets/img/about_us/aboutus.svg'; // Import SVG

function About() {
  return (
    <div className="main-wrapper justify-center items-center bg-white w-9/10 mx-auto">
      <div className="mt-2 flex flex-col items-center justify-center">
       

        
      </div>

      <div>
        <div className="flex justify-center items-center bg-white w-full mx-auto">
                  <img 
            src={aboutUsSVG} 
            alt="CFarm Infographic" 
            className="w-full h-auto md:w-4/5" 
          /> 
          {/* Sử dụng SVG */}
        </div>

        <div className="flex justify-center items-center bg-white w-full mx-auto">
          <div className="container bg-white mx-auto my-2 p-2 w-full">
            <div className="grid md:grid-cols-3 md:grid-rows-3 grid-cols-1 grid-rows-2 gap-1 h-auto">
              <div className="md:col-span-1 md:row-span-2 bg-white rounded-xl">
              
                <br/>
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
