import axios from 'axios';
import Cookies from 'js-cookie';
import { loginStart, loginSuccess, loginFailed, registerStart, registerSuccess, registerFailed } from './authSlice';
import { createOrderStart, createOrderSuccess, createOrderFailed } from './orderSlice';

// Thiết lập cơ bản cho axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://cfarm.vn/api', // Sử dụng biến môi trường hoặc URL mặc định
  headers: {
    'Content-Type': 'application/json',
  },
});

export const loginUser = async (dispatch, userCredentials, navigate) => {
  dispatch(loginStart());
  try {
    const res = await axiosInstance.post('/v1/auth/login', userCredentials);
    dispatch(loginSuccess(res.data));
    Cookies.set('currentUser', JSON.stringify(res.data), { expires: 7 }); // Lưu vào cookies, expires: 7 ngày

    // Kiểm tra nếu user là admin
    if (res.data.admin === 1) {
      navigate('/indexad');
    } else {
      navigate('/');
    }

    window.location.reload();
  } catch (err) {
    dispatch(loginFailed(err.response?.data?.message || 'Login failed'));
  }
};

// Hàm xử lý đăng ký
export const registerUser = async (dispatch, user) => {
  dispatch(registerStart());
  try {
    await axiosInstance.post('/v1/auth/register', user);
    dispatch(registerSuccess());
    return true;
  } catch (err) {
    dispatch(registerFailed(err.response?.data?.message || 'Registration failed'));
    console.error(err);
    return false;
  }
};

// Hàm xử lý đăng xuất
export const logoutUser = async (dispatch, navigate) => {
  try {
    await axiosInstance.post('/v1/auth/logout');
    Cookies.remove('currentUser'); // Xóa cookie
    navigate('/'); // Điều hướng đến trang đăng nhập sau khi đăng xuất
  } catch (err) {
    console.error('Logout failed', err);
  }
};

// Hàm xử lý lấy sản phẩm
export const fetchProducts = async () => {
  try {
    const res = await axiosInstance.get('/v1/products');
    return res.data;
  } catch (err) {
    console.error('Failed to fetch products', err);
    throw err;
  }
};

export const createOrder = async (dispatch, orderData, navigate) => {
  dispatch(createOrderStart());
  try {
    const res = await axiosInstance.post('/v1/orders', orderData);
    dispatch(createOrderSuccess(res.data));
    alert('Đặt hàng thành công!');
    localStorage.removeItem('cart');
    navigate('/');
  } catch (err) {
    console.error('Error creating order:', err); // Ghi lại chi tiết lỗi
    dispatch(createOrderFailed(err.response?.data?.message || 'Failed to create order'));
    alert('Có lỗi xảy ra, vui lòng thử lại.');
  }
};
