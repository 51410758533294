import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '../components/Logo';
import { registerUser } from '../redux/apiRequest';

const SignUp = () => {
  const [data, setData] = useState({
    username: '',
    phone: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    password: '',
    repass: '',
  });

  const [error, setError] = useState(''); // Thêm trạng thái để lưu trữ thông báo lỗi

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset lại lỗi trước khi gửi yêu cầu mới

    if (data.password !== data.repass) {
      setError('Mật khẩu không khớp');
      return;
    }

    const userRegistrationData = {
      username: data.username,
      phone: data.phone,
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      address4: data.address4,
      password: data.password,
      admin: 0, // Thêm thuộc tính admin với giá trị mặc định là 0
    };

    const result = await registerUser(dispatch, userRegistrationData);

    if (result) {
      navigate('/login');
    } else {
      setError('Đăng ký thất bại. Vui lòng thử lại.');
    }
  };

  return (
    <section id='signup' className='bg-gray-100 py-6'>
      <div className="container mx-auto px-5">
        <div className="form-content login bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto">
          <div className='flex justify-center mb-4'>
            <Logo width={150} height={70} />
          </div>
          <h3 className="form-title text-2xl font-semibold mb-4 text-center">Đăng ký tài khoản</h3>
          <p className="form-description text-center mb-6">Đăng nhập thành viên để mua hàng và nhận những ưu đãi đặc biệt từ chúng tôi</p>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>} {/* Hiển thị thông báo lỗi */}
          <form className='w-full' onSubmit={handleSubmit}>
            <div className="form-group mb-4">
              <label htmlFor="username" className="form-label block text-gray-700">Họ và tên</label>
              <input id="username" name='username' type="text" onChange={handleOnChange} required value={data.username} placeholder="Nhập tên đầy đủ của bạn" className="form-control w-full px-3 py-2 border rounded-lg" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="phone" className="form-label block text-gray-700">Số điện thoại</label>
              <input id="phone" name='phone' type="text" onChange={handleOnChange} required value={data.phone} placeholder="Nhập số điện thoại" className="form-control w-full px-3 py-2 border rounded-lg" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="address1" className="form-label block text-gray-700">Địa chỉ chi tiết</label>
              <input id="address1" name='address1' type="text" onChange={handleOnChange} required value={data.address1} placeholder="Tỉnh/ Thành Phố" className="form-control w-full px-3 py-2 border rounded-lg" />
              <input id="address2" name='address2' type="text" onChange={handleOnChange} required value={data.address2} placeholder="Huyện/ Thị Xã/ Thành Phố" className="form-control w-full px-3 py-2 border rounded-lg mt-2" />
              <input id="address3" name='address3' type="text" onChange={handleOnChange} required value={data.address3} placeholder="Xã/ Phường/ Thị trấn" className="form-control w-full px-3 py-2 border rounded-lg mt-2" />
              <input id="address4" name='address4' type="text" onChange={handleOnChange} required value={data.address4} placeholder="Số nhà/ Tên đường/ Thôn/ Xóm" className="form-control w-full px-3 py-2 border rounded-lg mt-2" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="password" className="form-label block text-gray-700">Mật khẩu</label>
              <input id="password" name='password' value={data.password} onChange={handleOnChange} required type="password" placeholder="Nhập mật khẩu" className="form-control w-full px-3 py-2 border rounded-lg" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="repass" className="form-label block text-gray-700">Nhập lại mật khẩu</label>
              <input id="repass" name='repass' value={data.repass} onChange={handleOnChange} required type="password" placeholder="Nhập lại mật khẩu" className="form-control w-full px-3 py-2 border rounded-lg" />
            </div>
            <button className="form-submit w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700" id="login_button">Đăng ký</button>
            <div className="form-submit mt-3 text-center">
              <Link to="/login" className="bg-center text-center block py-2 text-blue-500 hover:underline" id="login-button">Bạn đã có tài khoản? Đăng nhập ngay!</Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
