import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

// Tạo một instance axios với cấu hình cơ bản
const axiosInstance = axios.create({
  baseURL: 'https://cfarm.vn/api', // URL của API backend từ biến môi trường
  headers: {
    'Content-Type': 'application/json',
  },
});

// Tạo một async thunk để fetch dữ liệu người dùng
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_, { rejectWithValue }) => {
  try {
    // Lấy thông tin người dùng hiện tại từ cookies
    const currentUser = JSON.parse(Cookies.get('currentUser'));

    // Gửi yêu cầu GET đến API để lấy dữ liệu người dùng
    const response = await axiosInstance.get('/v1/users/all', {
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`
      }
    });

    // Trả về dữ liệu nếu yêu cầu thành công
    return response.data;
  } catch (error) {
    // Xử lý lỗi và trả về thông báo lỗi
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

// Tạo slice cho người dùng với Redux Toolkit
const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export default userSlice.reducer;
