import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaShoppingCart } from "react-icons/fa";
import { useOutletContext } from 'react-router-dom';
import { CiShop } from "react-icons/ci";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/main.css'; // Import file CSS
import banner1 from '../assets/img/banner1.png';
import Product from './Product';

function Cuahang() {
    const [products, setProducts] = useState([]);
    const { addToCart } = useOutletContext(); // Lấy hàm addToCart từ Outlet context

    useEffect(() => {
        axios.get('https://cfarm.vn/api/v1/products')
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the products!', error);
            });
    }, []);

    const detailProduct = (id) => {
        console.log(`Chi tiết sản phẩm với ID: ${id}`);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true, // Bật nút điều hướng
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="home-products mt-5" id="home-products">
            <div className="container mx-auto p-1">
                <br/><br/>
                <h2 className="text-xl font-bold text-green-700 mb-1 flex justify-center items-center">
                    <CiShop className="mr-2 text-xl" /> CỬA HÀNG CFARM
                </h2>
                
                <hr className="border-t-2 border-gray-300 mb-1" />
                <div className="home-slider">
                    <img src={banner1} alt="Banner 1" />
                    {/* 
                    <img src="./assets/img/banner-2.png" alt="Banner 2" />
                    <img src="./assets/img/banner-3.png" alt="Banner 3" />
                    <img src="./assets/img/banner-4.png" alt="Banner 4" />
                    <img src="./assets/img/banner-5.png" alt="Banner 5" />
                    */}
                    </div>
                    <br/>
                        <div className="sale-cuahang container mx-auto p-4 rounded-lg">
                            <br/>

                            <h1></h1>
                            <br/>
                            
                            
                        </div>
                <div className="container mx-auto p-4 background-cuahang rounded-lg">
                    <div className="">
                     
                        <h4 className="text-xl font-bold mb-4 text-white">Bán chạy nhất</h4>
                    </div>
                    <hr className="border-t-2 border-gray-300 my-4" />
                <Slider {...settings}>
                    {products.map(product => (
                        <div key={product.id} className="col-product w-full sm:w-[250px] sm:h-[360px]">
                            <article className="card-product border rounded-lg shadow-md overflow-hidden">
                                <div className="card-header">
                                    <a href="#" className="card-image-link" onClick={() => detailProduct(product.id)}>
                                    <img class="product-image h-full sm:h-1/4" src={`/product_img/${product.img}`} alt={product.name} />
                                    </a>
                                </div>
                                <div className="food-info p-2">
                                    <div className="card-content">
                                        <div className="card-title">
                                            <a href="#" className="card-title-link text-lg font-bold text-gray-900" onClick={() => detailProduct(product.id)}>
                                                {product.name}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-footer mt-2">
                                        <div className="product-price">
                                            <span className="current-price text-xl font-bold text-pink-500">
                                                {((product.price * 1000).toLocaleString('vi-VN'))}&nbsp;₫
                                            </span>
                                        </div>
                                        <div className="product-buy mt-2">
                                            <button 
                                                onClick={() => addToCart(product)} 
                                                className="card-button order-item bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 flex items-center justify-center"
                                            >
                                                <FaShoppingCart className="mr-2" /> Đặt món
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    ))}
                </Slider>
                <br/>
            <hr className="border-t-2 border-gray-300 mb-4" />

            </div> 
          
            </div>  

            <div className="container mx-auto p-4">
            <br/>
                <h4 className="text-xl font-bold mb-4 text-green-700">Thực Đơn Hôm Nay</h4>
                <hr className="border-t-2 border-gray-600 mb-4 " />
            </div>


            <div class="flex container mx-auto p-4">

                <div class="w-1/4 border-r-2 border-green-800">
                    Thẻ làm banner
                </div>

                <div class="w-3/4 max-h-[600px] overflow-y-auto">
                <h5 className=" font-bold mb-4 text-green-700 justify-center flex">ĐẶT NGAY, NHẬN LUÔN!</h5>
                <hr className="border-t-1 border-gray-300 mb-2" />
                        
                            <Product className="" />
                   
                </div>
            </div>
            
            </div>
      

        
    );
}

export default Cuahang;




