// src/components/AdminCheck.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const AdminCheck = ({ children }) => {
  const currentUser = JSON.parse(Cookies.get('currentUser') || '{}');

  if (currentUser && currentUser.admin === 1) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminCheck;