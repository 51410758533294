import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Cookies from 'js-cookie';

function ProductAdmin() {
  const [products, setProducts] = useState([]);
  const [formProduct, setFormProduct] = useState({ name: '', img: '', price: '', stock: '', description: '', level: '' });
  const [editingProduct, setEditingProduct] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/products`);
      setProducts(response.data);
    } catch (error) {
      setError('There was an error fetching the products: ' + error.message);
    }
  };

  const createProduct = async (product) => {
    try {
      const currentUser = Cookies.get('currentUser');
      const accessToken = JSON.parse(currentUser).accessToken;
      const formData = new FormData();
      Object.keys(product).forEach(key => {
        formData.append(key, product[key]);
      });
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/products`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      fetchProducts(); // Refresh the product list
    } catch (error) {
      setError('There was an error creating the product: ' + error.message);
    }
  };

  const updateProduct = async (id, updatedProduct) => {
    try {
      const currentUser = Cookies.get('currentUser');
      const accessToken = JSON.parse(currentUser).accessToken;
      const formData = new FormData();
      Object.keys(updatedProduct).forEach(key => {
        formData.append(key, updatedProduct[key]);
      });
      await axios.put(`${process.env.REACT_APP_API_URL}/v1/products/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      fetchProducts(); // Refresh the product list
    } catch (error) {
      setError('There was an error updating the product: ' + error.message);
    }
  };

  const deleteProduct = async (id) => {
    try {
      const currentUser = Cookies.get('currentUser');
      const accessToken = JSON.parse(currentUser).accessToken;
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1/products/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      fetchProducts(); // Refresh the product list
    } catch (error) {
      setError('There was an error deleting the product: ' + error.message);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFormProduct({ ...formProduct, img: acceptedFiles[0] });
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingProduct) {
      updateProduct(editingProduct.id, formProduct);
      setEditingProduct(null);
    } else {
      createProduct(formProduct);
    }
    setFormProduct({ name: '', img: '', price: '', stock: '', description: '', level: '' });
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl mb-4">Product Admin</h1>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input className="border p-2 mr-2" type="text" placeholder="Product name" value={formProduct.name} onChange={(e) => setFormProduct({ ...formProduct, name: e.target.value })} />
        <div {...getRootProps()} className="border p-2 mr-2">
          <input {...getInputProps()} />
          {formProduct.img ? <p>{formProduct.img.name}</p> : <p>Drag 'n' drop product image here, or click to select image</p>}
        </div>
        <input className="border p-2 mr-2" type="number" placeholder="Product price" value={formProduct.price} onChange={(e) => setFormProduct({ ...formProduct, price: e.target.value })} />
        <input className="border p-2 mr-2" type="number" placeholder="Product stock" value={formProduct.stock} onChange={(e) => setFormProduct({ ...formProduct, stock: e.target.value })} />
        <textarea className="border p-2 mr-2" placeholder="Product description" value={formProduct.description} onChange={(e) => setFormProduct({ ...formProduct, description: e.target.value })} />
        <input className="border p-2 mr-2" type="number" placeholder="Product level" value={formProduct.level} onChange={(e) => setFormProduct({ ...formProduct, level: e.target.value })} />
        <button className="bg-blue-500 text-white p-2" type="submit">{editingProduct ? 'Update Product' : 'Create Product'}</button>
      </form>
      <div className="mt-4">
        {products.map((product) => (
          <div key={product.id} className="border p-2 mb-2">
            <h2 className="text-xl">{product.name}</h2>
            <img src={`/product_img/${product.img}`} alt={product.name} className="w-24 h-24 object-cover" />
            <p>${product.price}</p>
            <p>Stock: {product.stock}</p>
            <p>{product.description}</p>
            <p>Level: {product.level}</p>
            <button className="bg-yellow-500 text-white p-2 mr-2" onClick={() => { setFormProduct(product); setEditingProduct(product); }}>Edit</button>
            <button className="bg-red-500 text-white p-2" onClick={() => deleteProduct(product.id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductAdmin;
