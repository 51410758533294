import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDonHang } from '../redux/donHangSlice';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Logo from '../components/Logo';
import dayjs from 'dayjs';
import axios from 'axios';

const DonHangList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const donhang = useSelector((state) => state.donhang.donhang);
    const donHangStatus = useSelector((state) => state.donhang.status);
    const error = useSelector((state) => state.donhang.error);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [filter, setFilter] = useState('');
    const [filteredDonhang, setFilteredDonhang] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        dispatch(fetchDonHang());
    }, [dispatch]);

    useEffect(() => {
        fetchOrderItems();
        fetchProducts();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [filter, donhang]);

    const fetchOrderItems = async () => {
        try {
            const response = await axios.get('https://cfarm.vn/api/v1/orderItems/all');
            setOrderItems(response.data);
        } catch (error) {
            console.error('Error fetching order items:', error);
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://cfarm.vn/api/v1/products');
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const applyFilter = () => {
        const today = dayjs().startOf('day');
        const tomorrow = dayjs().add(1, 'day').startOf('day');

        let filtered = [...donhang];

        if (filter === 'today') {
            filtered = filtered.filter(don => dayjs(don.delivery_date).isSame(today, 'day'));
        } else if (filter === 'tomorrow') {
            filtered = filtered.filter(don => dayjs(don.delivery_date).isSame(tomorrow, 'day'));
        } else if (filter) {
            filtered = filtered.filter(don => dayjs(don.delivery_date).isSame(dayjs(filter), 'day'));
        }

        setFilteredDonhang(filtered);
        setCurrentPage(0); // Reset to first page when filter is applied
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleFilterClick = (filterType) => {
        setFilter(filterType);
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const getProductById = (productId) => {
        return products.find(product => product.id === productId);
    };

    const getOrderItemsByOrderId = (orderId) => {
        return orderItems.filter(item => item.order_id === orderId);
    };

    let content;

    if (donHangStatus === 'loading') {
        content = <p>Đang tải đơn hàng của bạn...</p>;
    } else if (donHangStatus === 'succeeded') {
        const sortedDonhang = [...filteredDonhang].sort((a, b) => b.id - a.id);

        const offset = currentPage * itemsPerPage;
        const currentPageItems = sortedDonhang.slice(offset, offset + itemsPerPage);

        content = (
            <>
                <div className="donhang-list bg-white mt-3 container md:w-4/6">
                    {currentPageItems.map((don) => (
                        <div key={don.id} className="donhang-item border p-4 mb-4 rounded-lg shadow-lg mt-3">
                            <div className="flex">
                            <p><strong className="text-green-700">Mã đơn:</strong><b> #298{don.id}</b></p>
                            <p className="text-right sm:block border-spacing-1">| <b>Nhận:</b> {don.delivery_time} <b>|</b> {dayjs(don.delivery_date).format('DD/MM/YYYY')}</p>
                            </div> 
                            <button className="text-right border-4 border-green-700 bg-green-700 text-white rounded-xl mt-2 "><b>{don.delivery_method}</b></button>
                            {don.branch && <p><strong className="text-green-700">Chi Nhánh:</strong> <b>{don.branch}</b></p>}

                            <p><strong className="text-green-700">Tên Người Nhận:</strong><b> {don.recipient_name}</b></p>
                            <p><strong className="text-green-700">Số Điện Thoại:</strong> <b>{don.recipient_phone}</b></p>
                            <p><strong className="text-green-700">Địa Chỉ Người Nhận:</strong><b> {don.recipient_address}</b></p>
                            <p><strong className="text-green-700">Ghi Chú:</strong><b>  {don.note}</b></p>

                            <div className="order-items-list mt-4 bg-slate-100 rounded-md"><h1 className="font-bold text-green-800 ml-2">GIỎ HÀNG CỦA BẠN</h1>
                                {getOrderItemsByOrderId(don.id).map(item => (
                                    <div key={item.id} className="order-item border p-2 mb-2 rounded-lg"> 
                                        
                                        <b>Tên sản phẩm: </b><span className="flex mt-3 "><p className="">{getProductById(item.product_id)?.name || 'Unknown'}</p>  <p className="text-right w-full font-extrabold text-green-800">x {item.quantity}</p></span>
                                       
                                        <p><b>Giá một con: </b>{item.price}0 VND</p>                                
                                    </div>
                                ))}
                            </div>
                            <hr className="border-t-2 border-gray-300" />
                            <p><strong className="text-green-700">Phí Vận Chuyển:</strong> <b className="text-green-800">13.000 VND</b></p>
                            <p><strong className="text-green-700">Mã Giảm Giá</strong> <b className="text-green-800">:- {don.ma_giam || 0}<span className="ml-1">VND</span></b></p>
                            <p><strong className="text-green-700"> Tổng Số Tiền:</strong> <b className="text-green-800">{(don.total_amount+13000).toLocaleString('vi-VN')} VND</b></p>
                        </div>
                        
                    ))}
                </div>
                <ReactPaginate
                    previousLabel={'← Trước'}
                    nextLabel={'Tiếp →'}
                    pageCount={Math.ceil(sortedDonhang.length / itemsPerPage)}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination flex justify-center mt-4'}
                    pageClassName={'mx-1'}
                    pageLinkClassName={'px-3 py-1 border rounded'}
                    previousClassName={'mx-1'}
                    previousLinkClassName={'px-3 py-1 border rounded'}
                    nextClassName={'mx-1'}
                    nextLinkClassName={'px-3 py-1 border rounded'}
                    breakClassName={'mx-1'}
                    breakLinkClassName={'px-3 py-1 border rounded'}
                    activeClassName={'bg-green-500 text-white'}
                />
            </>
        );
    } else if (donHangStatus === 'failed') {
        content = <p>{error}</p>;
    }

    return (
        <section id='donhang-list' className='bg-gray-100 py-6'>
            <div className="container mx-auto px-5">
                <div className="donhang-content bg-white shadow-lg rounded-lg p-6">
                    <div className='flex justify-center mb-4'>
                        <Logo width={150} height={70} />
                    </div>
                    <h3 className="donhang-title text-2xl font-semibold mb-4 text-center text-green-700"><b>Danh Sách Đơn Hàng</b></h3>
                    <div className="flex justify-center mb-4">

                        
                        <button onClick={() => handleFilterClick('today')} className="bg-green-600 text-white py-2 px-4 sm:py-1 sm:px-2 rounded-md hover:bg-green-900 mx-2 flex">Hôm <span className="ml-1"> nay</span> <span className="md:block hidden ml-1 ">giao</span></button>
                        <button onClick={() => handleFilterClick('tomorrow')} className="bg-green-600 text-white py-2 px-4 sm:py-1 sm:px-2 rounded-md hover:bg-green-900 mx-2 sm:mx-1 flex">Ngày <span className="ml-1"> mai</span> <span className="md:block hidden ml-1">giao</span></button>
                        <input type="date" onChange={handleFilterChange} className="py-2 px-4 sm:py-1 sm:px-2 border rounded-lg mx-2" />
                    </div>
                    {content}
                    <div className="flex justify-center mt-4">
                        <button 
                            onClick={() => navigate('/userinfo')} 
                            className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-900"
                        >
                            Trở lại
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DonHangList;
