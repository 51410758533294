import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';


const UserInfo = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Lấy thông tin người dùng từ cookies
    const userCookie = Cookies.get('currentUser');
    if (userCookie) {
      setUserData(JSON.parse(userCookie));
    }
  }, []);

  const handleLogout = () => {
    Cookies.remove('currentUser');
    navigate('/login');
  };

  const handleEdit = () => {
    navigate('/DonHangList'); // Điều hướng đến trang chỉnh sửa hồ sơ người dùng
  };

  if (!userData) {
    return (
      <section id='userinfo' className='bg-gray-100 py-6'>
        <div className="container mx-auto px-5">
          <div className="form-content login bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto">
            <div className='flex justify-center mb-4'>
              <Logo width={150} height={70} />
            </div>
            <h3 className="form-title text-2xl font-semibold mb-4 text-center text-green-700">Thông tin người dùng</h3>
            <p className="form-description text-center mb-6 text-green-700">Không có thông tin người dùng</p>
            <div className="form-submit mt-3 text-center">
              <Link to="/signup" className="bg-center text-center block py-2 text-blue-500 hover:underline" id="signup-button">Đăng ký ngay!</Link>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id='userinfo' className='bg-gray-100 py-6'>
  <div className="container mx-auto px-5">
    <div className="f bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto">
      <div className='flex justify-center mb-4'>
        <Logo width={150} height={70} />
      </div>
      <h3 className="form-title text-2xl font-semibold mb-4 text-center text-green-800">Thông tin người dùng</h3>
      <table className="min-w-full bg-white w-full sm:w-4/5 mx-auto border-collapse">
  <thead>
    <tr className="bg-gray-200">
      <th className="py-2 px-4 text-green-800 border-b">Mục</th>
      <th className="py-2 px-4 text-green-800 border-b">Thông tin</th>
    </tr>
  </thead>
  <tbody>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Họ và tên</b></td>
      <td className="border px-4 py-2">{userData.username}</td>
    </tr>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Số điện thoại</b></td>
      <td className="border px-4 py-2">{userData.phone}</td>
    </tr>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Tỉnh thành phố</b></td>
      <td className="border px-4 py-2">{userData.address1}</td>
    </tr>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Huyện/ Thị xã/ Thành phố</b></td>
      <td className="border px-4 py-2">{userData.address2}</td>
    </tr>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Phường/ Xã/ Thị trấn</b></td>
      <td className="border px-4 py-2">{userData.address3}</td>
    </tr>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Thôn/ Xóm/ Tổ dân phố/ Đường</b></td>
      <td className="border px-4 py-2">{userData.address4}</td>
    </tr>
    <tr className="even:bg-gray-100">
      <td className="border px-4 py-2 text-green-700"><b>Vai trò</b></td>
      <td className="border px-4 py-2 hover:text-green-600">
  {userData.admin ? <Link to="/indexad">Admin</Link> : 'Khách hàng'}
</td>
    </tr>
  </tbody>
</table>

      <div className="flex justify-between mt-6">
        <button 
          onClick={handleLogout} 
          className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-900"
        >
          Đăng xuất
        </button>
        <button 
          onClick={handleEdit} 
          className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-900"
        >
          Đơn hàng của bạn
        </button>
      </div>
    </div>
  </div>
</section>


  );
}

export default UserInfo;
