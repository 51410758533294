import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import './assets/css/main.css';
import './assets/css/home-responsive.css';
import './assets/font/font-awesome-pro-v6-6.2.0/css/all.min.css';
import './assets/img/zlogo.png';
import SummaryApi from './common';
import Header from './components/Header';
import Footer from './components/Footer';
import Header_bottom from './components/Header_bottom';
import Cart from './components/Cart';
import { CartProvider } from './components/CartContext';
import Product from './pages/Product';
import DonHangList from './pages/DonHangList';

function App() {
  const [showCart, setShowCart] = useState(false); // Quản lý trạng thái hiển thị giỏ hàng
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []); // Quản lý trạng thái giỏ hàng

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleCartClick = () => {
    setShowCart(true);
  };

  const addToCart = (product) => {
    const existingProduct = cart.find(item => item.productId === product.id);
    if (existingProduct) {
      setCart(cart.map(item => 
        item.productId === product.id 
          ? { ...item, quantity: item.quantity + 1 } 
          : item
      ));
    } else {
      setCart([...cart, { productId: product.id, name: product.name, price: product.price, image: product.image, quantity: 1 }]);
    }
  };

  return (
    <CartProvider>
      <div className="">
        <Header onCartClick={handleCartClick} cart={cart} /> {/* Truyền trạng thái và hàm cần thiết vào Header */}
        <Cart showCart={showCart} setShowCart={setShowCart} cart={cart} setCart={setCart} /> {/* Truyền trạng thái và hàm cần thiết vào Cart */}
        
        <Header_bottom />
        <main className="min-h-[calc(80vh-80px)]">
          <Outlet context={{ addToCart }} /> {/* Truyền hàm addToCart vào Outlet để các component con có thể sử dụng */}
       
        </main>
        <Footer />
      </div>
    </CartProvider>
  );
}

export default App;
