
import { FaShoppingCart } from "react-icons/fa";
import { IoRemoveCircle } from "react-icons/io5";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

const Cart = ({ showCart, setShowCart, cart, setCart }) => {
    const navigate = useNavigate();
    

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const handleCloseCart = () => {
        setShowCart(false);
    };

    const handleRemoveItem = (productId) => {
        const updatedCart = cart.filter(item => item.productId !== productId);
        setCart(updatedCart);
    };

    const handleIncreaseQuantity = (productId) => {
        const updatedCart = cart.map(item => {
            if (item.productId === productId) {
                return { ...item, quantity: item.quantity + 1 };
            }
            return item;
        });
        setCart(updatedCart);
    };
    

    const handleDecreaseQuantity = (productId) => {
        const updatedCart = cart.map(item => {
            if (item.productId === productId && item.quantity > 1) {
                return { ...item, quantity: item.quantity - 1 };
            }
            return item;
        });
        setCart(updatedCart);
    };

    const getTotalPrice = () => {
        return cart.reduce((total, item) => total + item.price * item.quantity * 1000, 0);
    };

    const handleOrder = () => {
        const currentUser = Cookies.get('currentUser');
        if (!currentUser) {
            alert('Bạn cần đăng nhập để đặt hàng.');
            navigate('/login');
            return;
        }
        setShowCart(false);
        navigate('/checkout');
    };

    return (
        <div className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center ${showCart ? '' : 'hidden'}`} style={{ zIndex: 9999 }}>
            <div className="bg-white p-6 rounded-lg w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl">
                <h2 className="text-2xl font-bold mb-4 text-green-600 flex items-center">
                    <FaShoppingCart className="mr-2" /> Giỏ hàng
                </h2>
                {cart.length === 0 ? (
                    <p className="text-gray-600">Giỏ hàng của bạn đang trống.</p>
                ) : (
                    <ul className="space-y-4">
                        {cart.map(item => (
                            <li key={item.productId} className="flex justify-between items-center p-4 border-2 border-gray-200 rounded-lg shadow-sm bg-gray-50">
                                <div>
                              
                                    <h3 className="text-lg font-semibold">{item.name}</h3>
                                    <p className="text-gray-600">Giá: {(item.price * 1000).toLocaleString('vi-VN')} VND</p>
                                    <div className="flex items-center mt-2">
                                        <button 
                                            onClick={() => handleDecreaseQuantity(item.productId)} 
                                            className="bg-gray-300 text-gray-700 px-2 py-1 rounded-l-lg">
                                            <AiOutlineMinus />
                                        </button>
                                        <span className="px-4 py-1 bg-gray-100 border-t border-b">{item.quantity}</span>
                                        <button 
                                            onClick={() => handleIncreaseQuantity(item.productId)} 
                                            className="bg-gray-300 text-gray-700 px-2 py-1 rounded-r-lg">
                                            <AiOutlinePlus />
                                        </button>
                                    </div>
                                </div>
                                <button 
                                    onClick={() => handleRemoveItem(item.productId)} 
                                    className="text-red-600 text-2xl ml-4">
                                    <IoRemoveCircle />
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                {cart.length > 0 && (
                    <>
                        <p className="text-gray-600">Tổng tiền: {getTotalPrice().toLocaleString('vi-VN')} VND</p>
                        <button 
                            onClick={handleOrder} 
                            className="mt-4 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors duration-500">
                            Đặt hàng
                        </button>
                    </>
                )}
                <button 
                    onClick={handleCloseCart} 
                    className="mt-4 ml-4 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-800 transition-colors duration-500">
                    Tiếp tục mua hàng
                </button>
            </div>
        </div>
    );
};

export default Cart;