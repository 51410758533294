// src/redux/dangkySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Tạo một instance axios với cấu hình cơ bản
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // URL của API backend từ biến môi trường
  headers: {
    'Content-Type': 'application/json',
  },
});

export const dangkyUser = createAsyncThunk(
  'dangky/dangkyUser',
  async (contactInfo, { rejectWithValue }) => {
    try {
      // Không thêm '/api' vào endpoint vì baseURL đã chứa '/api'
      const response = await axiosInstance.post('/v1/usernhantin/nhantin', { contactInfo });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dangkySlice = createSlice({
  name: 'dangky',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dangkyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(dangkyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(dangkyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dangkySlice.reducer;
