import React from 'react';


import { useOutletContext } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";

function ProductDetail({ product, onClose }) { 
    const { addToCart } = useOutletContext();
    return (
        <div class="modal-container mdl-cnt w-11/12 sm:w-full" id="product-detail-content" bis_skin_checked="1" style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000}}>
            <div class="modal-content mdl-cnt">
                <div class="modal-header mdl-hdr">
                    <h2 class="modal-title mdl-ttl text-xl text-green-700"><b>Chi tiết sản phẩm</b></h2>
                    <button class="text-xl text-green-800" onClick={onClose}><IoMdArrowRoundBack /></button>
                </div>
                <div class="modal-body mdl-bdy">
                    <div class="product-detail-content prd-dtl-cnt">
                        <div class="product-image-box prd-img-bx">
                            <img class="product-image prd-img" src={`/product_img/${product.img}`} alt={product.name} />
                        </div>
                        <div class="product-info-box prd-inf-bx">
                            <h2 class="product-title prd-ttl text-green-800">{product.name}</h2>
                            <div class="priceBox prcBx" bis_skin_checked="1">
                                <span class="current-price crnt-prc">{((product.price * 1000).toLocaleString('vi-VN'))}&nbsp;₫</span>
                            </div>
                            <p class="product-description prd-dsc">{product.description}</p>
                            <div class="product-actions prd-act">
                                <button class="button-dathangngay btn-dthng" data-product={product.id} onClick={onClose}>Đặt hàng ngay</button>
                                <button class="button-themvaogio btn-tmvgo" data-product={product.id} onClick={() => addToCart(product)} className="card-button order-item bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                                            <i className="fa-regular fa-cart-shopping-fast mr-2"></i>Thêm vào giỏ</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;