import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { logoutUser } from '../redux/apiRequest';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await logoutUser();
        Cookies.remove('currentUser');
        localStorage.removeItem('cart'); // Xóa thông tin giỏ hàng từ localStorage
        navigate('/');
        window.location.reload(); // Làm mới trang sau khi điều hướng về trang chủ
      } catch (err) {
        console.error('Logout failed', err);
      }
    };

    performLogout();
  }, [navigate]);

  return (
    <div>
      <h1>Đang đăng xuất...</h1>
    </div>
  );
};

export default Logout;
