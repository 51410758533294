import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import ProductDetail from './ProductDetail';

function Product() {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showNotification, setShowNotification] = useState(false); // New state for notification
    const { addToCart } = useOutletContext();

    useEffect(() => {
        axios.get('https://cfarm.vn/api/v1/products')
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the products!', error);
            });
    }, []);

    const detailProduct = (event, product) => {
        event.preventDefault();
        setSelectedProduct(product);
    };

    const handleAddToCart = (product) => {
        addToCart(product);
        setShowNotification(true); // Show the notification
        setTimeout(() => setShowNotification(false), 2000); // Hide it after 2 seconds
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <div className="relative">
                </div>
            </div>
            <div className="flex flex-wrap justify-center gap-4 sm:gap-[30px]">
                    {showNotification && 
                        <div className="bg-green-500 border-l-4 border-green-700 text-white px-4 py-3 rounded fixed top-0 right-0 m-4 text-sm z-50 transition-all duration-500 ease-in-out" role="alert">
                            <span className="block sm:inline">Thêm vào giỏ hàng thành công!</span>
                            
                        </div>
                    } {/* Notification */}      
                {products.map(product => (
                    <div key={product.id} className="col-product w-full sm:w-[250px] sm:h-[350px]">
                        <article className="card-product border rounded-lg shadow-md overflow-hidden">
                            <div className="">
                                <a href="#" className="card-image-link" onClick={(event) => detailProduct(event, product)}>
                                    <img class="product-image h-full sm:h-1/4" src={`/product_img/${product.img}`} alt={product.name} />
                                </a>
                            </div>
                            <div className="food-info p-2">
                                <div className="card-content">
                                    <div className="card-title">
                                        <a href="#" className="card-title-link text-lg font-bold text-gray-900" onClick={(event) => detailProduct(event, product)}>
                                            {product.name}
                                        </a>
                                    </div>
                                </div>
                                <div className="card-footer mt-2">
                                    <div className="product-price">
                                        <span className="current-price text-xl font-bold text-pink-500">
                                            {((product.price * 1000).toLocaleString('vi-VN'))}&nbsp;₫
                                        </span>
                                    </div>
                                    <div className="product-buy mt-2">
                                        <button onClick={() => handleAddToCart(product)} className="card-button order-item bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                                            <i className="fa-regular fa-cart-shopping-fast mr-2"></i> Đặt món
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                ))}
            </div>
            {selectedProduct && <ProductDetail product={selectedProduct} onClose={() => setSelectedProduct(null)} />}
        </div>
    );
}

export default Product;