import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDonHang } from '../redux/donHangSlice';
import AdminCheck from '../admin/Admincheck';
import Cookies from 'js-cookie';
import axios from 'axios';

const ITEMS_PER_PAGE = 5;

const CheckDonHangAd = () => {
  const dispatch = useDispatch();
  const donhang = useSelector((state) => state.donhang.donhang);
  const donHangStatus = useSelector((state) => state.donhang.status);
  const error = useSelector((state) => state.donhang.error);

  const [selectedDate, setSelectedDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrderItems, setSelectedOrderItems] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (donHangStatus === 'idle') {
      dispatch(fetchAllDonHang());
    }
  }, [donHangStatus, dispatch]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://cfarm.vn/api/v1/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setCurrentPage(1); // Reset trang về trang đầu tiên khi thay đổi ngày
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewDetails = async (orderId) => {
    if (selectedOrderItems[orderId]) {
      setSelectedOrderItems((prev) => ({ ...prev, [orderId]: null }));
    } else {
      try {
        const response = await axios.get('https://cfarm.vn/api/v1/orderItems/all');
        const allOrderItems = response.data;
        const filteredOrderItems = allOrderItems.filter(item => item.order_id === orderId);
        setSelectedOrderItems((prev) => ({ ...prev, [orderId]: filteredOrderItems }));
      } catch (error) {
        console.error('Error fetching order items:', error);
      }
    }
  };

  const getProductById = (productId) => {
    return products.find(product => product.id === productId);
  };

  let content;
  let dateOptions = [];
  let totalAmountByMonth = {};
  let ordersCountByMonth = {};
  let pageNumbers = [];

  if (donHangStatus === 'loading') {
    content = <div>Loading...</div>;
  } else if (donHangStatus === 'succeeded') {
    // Nhóm đơn hàng theo ngày tạo và sắp xếp các nhóm từ mới đến cũ
    const groupedOrders = donhang.reduce((groups, don) => {
      const date = new Date(don.createdAt).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(don);
      return groups;
    }, {});

    const sortedDates = Object.keys(groupedOrders).sort((a, b) => new Date(b) - new Date(a));

    // Lọc các đơn hàng dựa trên ngày được chọn
    const filteredDates = selectedDate
      ? sortedDates.filter((date) => new Date(date).toLocaleDateString() === new Date(selectedDate).toLocaleDateString())
      : sortedDates;

    // Tạo danh sách các tùy chọn ngày
    dateOptions = sortedDates.map((date) => (
      <option key={date} value={date}>
        {date}
      </option>
    ));

    // Nhóm đơn hàng theo tháng và tính tổng `total_amount` và số lượng đơn hàng cho mỗi tháng
    totalAmountByMonth = donhang.reduce((totals, don) => {
      const month = new Date(don.createdAt).toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!totals[month]) {
        totals[month] = 0;
      }
      totals[month] += don.total_amount;
      return totals;
    }, {});

    ordersCountByMonth = donhang.reduce((counts, don) => {
      const month = new Date(don.createdAt).toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!counts[month]) {
        counts[month] = 0;
      }
      counts[month]++;
      return counts;
    }, {});

    // Lưu tổng số tiền và tổng số lượng đơn hàng của tháng hiện tại vào cookies
    const currentMonth = new Date().toLocaleString('default', { month: 'long', year: 'numeric' });
    if (totalAmountByMonth[currentMonth]) {
      Cookies.set('totalAmountCurrentMonth', totalAmountByMonth[currentMonth].toFixed(2));
    }
    if (ordersCountByMonth[currentMonth]) {
      Cookies.set('ordersCountCurrentMonth', ordersCountByMonth[currentMonth]);
    }

    // Tính toán tổng số trang
    const totalPages = Math.ceil(filteredDates.length / ITEMS_PER_PAGE);

    // Lấy các đơn hàng cho trang hiện tại
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentDates = filteredDates.slice(startIndex, endIndex);

    content = currentDates.map((date) => (
      <div key={date} className="border-spacing-1">
        <b><h2 className="text-green-700">{date}</h2></b>
        {groupedOrders[date].map((don) => (
          <div key={don.id} style={{ border: '1px solid #ccc', marginBottom: '10px', padding: '10px' }}>
            <h3>Order ID: {don.id}</h3>
            <p>Recipient Name: {don.recipient_name}</p>
            <p>Recipient Phone: {don.recipient_phone}</p>
            <p>Recipient Address: {don.recipient_address}</p>
            <p>Delivery Method: {don.delivery_method}</p>
            <p>Delivery Date: {new Date(don.delivery_date).toLocaleDateString()}</p>
            <p>Delivery Time: {don.delivery_time}</p>
            <p>Note: {don.note}</p>
            <p>Total Amount: {don.total_amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            <p>Created At: {new Date(don.createdAt).toLocaleString()}</p>
            <p>Updated At: {new Date(don.updatedAt).toLocaleString()}</p>
            <button className="border-4 border-green-700 bg-green-700 text-white rounded-xl mt-2" onClick={() => handleViewDetails(don.id)}>Xem chi tiết</button>
            {selectedOrderItems[don.id] && (
              <div className="order-items-list mt-4 bg-green-100">
                {selectedOrderItems[don.id].map(item => (
                  <div key={item.id} className="order-item border p-2 mb-2 rounded-lg">
                    <p>Tên hàng: {getProductById(item.product_id)?.name || 'Unknown'}</p>
                    <p>Số lượng: {item.quantity}</p>
                    <p>Giá: {item.price}</p>
                    <p>Created At: {new Date(item.createdAt).toLocaleDateString()}</p>
                    <p>Updated At: {new Date(item.updatedAt).toLocaleDateString()}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    ));

    // Tạo các nút phân trang
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} onClick={() => handlePageChange(i)} disabled={i === currentPage}>
          {i}
        </button>
      );
    }
  } else if (donHangStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <AdminCheck>
      <div className="container">
        <br />
        <br />
        <br />
        <h1 className="text-2xl text-green-600">Kiểm tra order dành cho Admin</h1><br />
        <div>
          <b><label htmlFor="filterDate boder-2">Chọn ngày tra order: </label></b>
          <select id="filterDate" value={selectedDate} onChange={handleDateChange}>
            <option value="">Tất cả các đơn</option>
            {dateOptions}
          </select>
        </div>
        <div>
          <h2 className="text-xl text-green-600">Tổng số tiền theo tháng</h2>
          <ul>
            {Object.entries(totalAmountByMonth).map(([month, total]) => (
              <li key={month}>
                <b>{month}:</b> {total.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} VND
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="text-xl text-green-600">Số lượng đơn hàng theo tháng</h2>
          <ul>
            {Object.entries(ordersCountByMonth).map(([month, count]) => (
              <li key={month}>
                <b>{month}:</b> {count} đơn hàng
              </li>
            ))}
          </ul>
        </div>
        {content}
        <div>
          <h2 className="text-xl text-green-600">Trang</h2>
          <div>{pageNumbers}</div>
        </div>
      </div>
    </AdminCheck>
  );
};

export default CheckDonHangAd;
