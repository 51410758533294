import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

// Tạo một instance axios với cấu hình cơ bản
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Sử dụng biến môi trường cho URL của API backend
  headers: {
    'Content-Type': 'application/json',
  },
});

// Tạo một async thunk để fetch tất cả dữ liệu đơn hàng
export const fetchAllDonHang = createAsyncThunk('donhang/fetchAllDonHang', async () => {
  const response = await axiosInstance.get('/v1/donhang/checkdonhangad'); // Sử dụng axiosInstance với baseURL đã cấu hình
  return response.data;
});

// Tạo một async thunk để fetch dữ liệu đơn hàng cho người dùng hiện tại
export const fetchDonHang = createAsyncThunk('donhang/fetchDonHang', async (_, { rejectWithValue }) => {
  try {
    // Lấy thông tin người dùng hiện tại từ cookies
    const currentUser = JSON.parse(Cookies.get('currentUser'));

    // Gửi yêu cầu GET đến API để lấy dữ liệu đơn hàng
    const response = await axiosInstance.get(`/v1/donhang/donhang/${currentUser.id}`, {
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`
      }
    });

    // Trả về dữ liệu nếu yêu cầu thành công
    return response.data;
  } catch (error) {
    // Xử lý lỗi và trả về thông báo lỗi
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

// Tạo slice cho đơn hàng với Redux Toolkit
const donHangSlice = createSlice({
  name: 'donhang',
  initialState: {
    donhang: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDonHang.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDonHang.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.donhang = action.payload;
      })
      .addCase(fetchDonHang.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAllDonHang.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllDonHang.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.donhang = action.payload;
      })
      .addCase(fetchAllDonHang.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default donHangSlice.reducer;
