import React from 'react';
import banner1 from '../assets/img/banner1.png';
import Product from './Product';


function Home() {
  return (
    <main className="main-wrapper">
      <div className="container" id="trangchu">
        <div className="home-slider">
          <img src={banner1} alt="Banner 1" />
          {/* 
          <img src="./assets/img/banner-2.png" alt="Banner 2" />
          <img src="./assets/img/banner-3.png" alt="Banner 3" />
          <img src="./assets/img/banner-4.png" alt="Banner 4" />
          <img src="./assets/img/banner-5.png" alt="Banner 5" />
          */}
        </div>
        <div className="home-service" id="home-service">
          <div className="home-service-item">
            <div className="home-service-item-icon">
              <i className="fa-light fa-person-carry-box"></i>
            </div>
            <div className="home-service-item-content">
              <h4 className="home-service-item-content-h">GIAO HÀNG NHANH</h4>
              <p className="home-service-item-content-desc">Cho tất cả đơn hàng</p>
            </div>
          </div>
          <div className="home-service-item">
            <div className="home-service-item-icon">
              <i className="fa-light fa-shield-heart"></i>
            </div>
            <div className="home-service-item-content">
              <h4 className="home-service-item-content-h">SẢN PHẨM AN TOÀN</h4>
              <p className="home-service-item-content-desc">Cam kết chất lượng</p>
            </div>
          </div>
          <div className="home-service-item">
            <div className="home-service-item-icon">
              <i className="fa-light fa-headset"></i>
            </div>
            <div className="home-service-item-content">
              <h4 className="home-service-item-content-h">HỖ TRỢ 24/7</h4>
              <p className="home-service-item-content-desc">Tất cả ngày trong tuần</p>
            </div>
          </div>
          <div className="home-service-item">
            <div className="home-service-item-icon">
              <i className="fa-light fa-circle-dollar"></i>
            </div>
            <div className="home-service-item-content">
              <h4 className="home-service-item-content-h">HOÀN LẠI TIỀN</h4>
              <p className="home-service-item-content-desc">Nếu không hài lòng</p>
            </div>
          </div>
        </div>
        <div className="home-title-block" id="home-title">
          <h2 className="home-title">Thực Đơn Hôm Nay!</h2>
        </div>     
      </div>
      <Product />
      
             

      
    </main>
      
  );
}

export default Home;
