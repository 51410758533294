import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Thunk để truy xuất đơn hàng
export const fetchUserOrders = createAsyncThunk(
    'orders/fetchUserOrders',
    async (_, thunkAPI) => {
        try {
            const token = localStorage.getItem('token'); // Lấy token từ localStorage
            const response = await axios.get('/order/user-orders', {
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const orderSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        isLoading: false,
        error: null
    },
    reducers: {
        createOrderStart: (state) => {
            state.isLoading = true;
        },
        createOrderSuccess: (state, action) => {
            state.isLoading = false;
            state.orders.push(action.payload);
        },
        createOrderFailed: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserOrders.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchUserOrders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orders = action.payload;
            })
            .addCase(fetchUserOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

export const { createOrderStart, createOrderSuccess, createOrderFailed } = orderSlice.actions;
export default orderSlice.reducer;
