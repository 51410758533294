import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import { IoSearchCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const HeaderBottom = () => {
  const [scrollY, setScrollY] = useState(0);
  const [hidden, setHidden] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > scrollY) {
      setHidden(true);
    } else {
      setHidden(false);
    }
    setScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <nav className={`header-bottom bg-gray-800 h-10px fixed w-full transition-transform duration-300 ${hidden ? '-translate-y-full' : 'translate-y-0'}`}>
      <div className="container mx-auto px-5 h-full">
        <div className="flex justify-between items-center h-full">
        
          <ul className="menu-list flex space-x-6 h-full items-center">
            <li className="menu-list-item">
              <Link to="/" className="menu-link text-white hover:text-gray-400 ">Trang chủ</Link>
            </li>
            <li className="menu-list-item">
              <Link to="/about" className="menu-link text-white hover:text-gray-400">Về chúng tôi</Link>
            </li>
            <li className="menu-list-item">
              <Link to="/cuahang" className="menu-link text-white hover:text-gray-400">Cửa hàng</Link>
            </li>
          </ul>
          <div className="flex items-center">
            <IoSearchCircleOutline className="text-white text-3xl cursor-pointer" />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HeaderBottom;
