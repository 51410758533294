import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createOrder } from '../redux/apiRequest';

const Checkout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cart, setCart] = useState([]);
    const [formData, setFormData] = useState({
        deliveryMethod: 'giaotannoi',
        deliveryDate: '',
        deliveryTime: '08:00',
        branch: '',
        note: '',
        recipientName: '',
        recipientPhone: '',
        recipientAddress: ''
    });
    useEffect(() => {
        const currentUser = Cookies.get('currentUser');
        if (!currentUser) {
            alert('Bạn cần đăng nhập để tiếp tục.');
            navigate('/login');
            return;
        }

        const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(savedCart);
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentUser = Cookies.get('currentUser');
        if (!currentUser) {
            alert('Bạn cần đăng nhập để đặt hàng.');
            return;
        }

        const orderData = {
            user_id: JSON.parse(currentUser).id,
            order_items: cart.map(item => ({
                product_id: item.productId,
                quantity: item.quantity,
                price: item.price
            })),
            delivery_method: formData.deliveryMethod,
            delivery_date: formData.deliveryDate,
            delivery_time: formData.deliveryTime,
            branch: formData.branch,
            note: formData.note,
            recipient_name: formData.recipientName,
            recipient_phone: formData.recipientPhone,
            recipient_address: formData.deliveryMethod === 'giaotannoi' ? formData.recipientAddress : formData.branch,
            total_amount: cart.reduce((total, item) => total + item.price * item.quantity, 0) * 1000 // Không thêm phí vận chuyển
        };

        try {
            await createOrder(dispatch, orderData, navigate);
        } catch (error) {
            console.error('Error:', error);
            alert('Có lỗi xảy ra, vui lòng thử lại.');
        }
    };

    return (
        <main className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
                <div className="border rounded-lg p-4 shadow-lg">
                    <div className="mb-4 ">
                        <h2 className="font-bold text-lg mb-2">Thông tin đơn hàng</h2>
                        <div className="mb-4">
                            <p className="font-semibold">Hình thức giao nhận</p>
                            <div className="flex space-x-2">
                                <button className={`p-2 rounded ${formData.deliveryMethod === 'giaotannoi' ? 'bg-green-600 text-white' : 'bg-gray-200'}`} onClick={() => setFormData({ ...formData, deliveryMethod: 'giaotannoi' })}>
                                    <i className="fa-duotone fa-moped" style={{ '--fa-secondary-opacity': 1.0, '--fa-primary-color': 'dodgerblue', '--fa-secondary-color': '#ffb100' }}></i>
                                    Giao tận nơi
                                </button>
                                <button className={`p-2 rounded ${formData.deliveryMethod === 'tudenlay' ? 'bg-green-600 text-white' : 'bg-gray-200'}`} onClick={() => setFormData({ ...formData, deliveryMethod: 'tudenlay' })}>
                                    <i className="fa-duotone fa-store" style={{ '--fa-secondary-opacity': 1.0, '--fa-primary-color': 'dodgerblue', '--fa-secondary-color': '#ffb100' }}></i>
                                    Tự đến lấy
                                </button>
                            </div>
                        </div>
                        {formData.deliveryMethod === 'giaotannoi' && (
                            <div className="mb-4">
                                <p className="font-semibold">Ngày giao</p>
                                <input 
                                    type="date" 
                                    name="deliveryDate" 
                                    value={formData.deliveryDate} 
                                    onChange={handleChange} 
                                    className="border rounded p-2 w-full"
                                />
                            </div>
                        )}
                        {formData.deliveryMethod === 'giaotannoi' && (
                            <div className="mb-4">
                                <p className="font-semibold">Giờ giao</p>
                                <select 
                                    name="deliveryTime" 
                                    value={formData.deliveryTime} 
                                    onChange={handleChange} 
                                    className="border rounded p-2 w-full"
                                >
                                    <option value="08:00">08:00 - 09:00</option>
                                    <option value="09:00">09:00 - 10:00</option>
                                    <option value="10:00">10:00 - 11:00</option>
                                    <option value="11:00">11:00 - 12:00</option>
                                    <option value="12:00">12:00 - 13:00</option>
                                    <option value="13:00">13:00 - 14:00</option>
                                    <option value="14:00">14:00 - 15:00</option>
                                    <option value="15:00">15:00 - 16:00</option>
                                    <option value="16:00">16:00 - 17:00</option>
                                    <option value="17:00">17:00 - 18:00</option>
                                    <option value="18:00">18:00 - 19:00</option>
                                    <option value="19:00">19:00 - 20:00</option>
                                    <option value="20:00">20:00 - 21:00</option>
                                    <option value="21:00">21:00 - 22:00</option>
                                </select>
                            </div>
                        )}
                        {formData.deliveryMethod === 'tudenlay' && (
                            <div className="mb-4">
                                <p className="font-semibold">Lấy hàng tại chi nhánh</p>
                                <div className="mb-2">
                                    <input 
                                        type="radio" 
                                        name="branch" 
                                        id="chinhanh-1" 
                                        value="273 An Dương Vương, Phường 3, Quận 5" 
                                        checked={formData.branch === '273 An Dương Vương, Phường 3, Quận 5'}
                                        onChange={handleChange}
                                        className="mr-2"
                                    />
                                    <label htmlFor="chinhanh-1">Hoàng Hoa Thám, Thành phố Chí Linh, Tỉnh Hải Dương</label>
                                </div>
                                <div className="mb-2">
                                    <input 
                                        type="radio" 
                                        name="branch" 
                                        id="chinhanh-2" 
                                        value="04 Tôn Đức Thắng, Phường Bến Nghé, Quận 1" 
                                        checked={formData.branch === '04 Tôn Đức Thắng, Phường Bến Nghé, Quận 1'}
                                        onChange={handleChange}
                                        className="mr-2"
                                    />
                                    <label htmlFor="chinhanh-2">Hoàng Hoa Thám, Thành phố Chí Linh, Tỉnh Hải Dương</label>
                                </div>
                            </div>
                        )}
                        <div className="mb-4">
                            <p className="font-semibold">Ghi chú đơn hàng</p>
                            <textarea 
                                name="note" 
                                className="border rounded p-2 w-full" 
                                placeholder="Nhập ghi chú" 
                                value={formData.note} 
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h2 className="font-bold text-lg mb-2">Thông tin người nhận</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <input 
                                    id="tennguoinhan" 
                                    name="recipientName" 
                                    type="text" 
                                    placeholder="Tên người nhận" 
                                    className="border rounded p-2 w-full mb-2" 
                                    value={formData.recipientName} 
                                    onChange={handleChange} 
                                    required
                                />
                                <span className="form-message"></span>
                            </div>
                            <div className="mb-4">
                                <input 
                                    id="sdtnhan" 
                                    name="recipientPhone" 
                                    type="text" 
                                    placeholder="Số điện thoại nhận hàng" 
                                    className="border rounded p-2 w-full mb-2" 
                                    value={formData.recipientPhone} 
                                    onChange={handleChange} 
                                    required
                                />
                                <span className="form-message"></span>
                            </div>
                            {formData.deliveryMethod === 'giaotannoi' && (
                                <div className="mb-4">
                                    <input 
                                        id="diachinhan" 
                                        name="recipientAddress" 
                                        type="text" 
                                        placeholder="Địa chỉ nhận hàng" 
                                        className="border rounded p-2 w-full mb-2" 
                                        value={formData.recipientAddress} 
                                        onChange={handleChange} 
                                        required
                                    />
                                   
                                   <span className="form-message"></span>
                                </div>
                            )}
                            <button type="submit" className="bg-green-600 text-white p-2 rounded w-full">Đặt hàng</button>
                        </form>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow-lg">
                    <h2 className="font-bold text-lg mb-2">Đơn hàng</h2>
                    <div className="mb-4">
                        {cart.map(item => (
                            <div className="flex justify-between items-center mb-2" key={item.productId}>
                                <div className="font-semibold">{item.quantity}x</div>
                                <div>{item.name}</div>
                            </div>
                        ))}
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <div className="font-semibold">
                                Tiền hàng 
                                <span className="ml-2">({cart.length} món)</span>
                            </div>
                            <div>
                                <span>{(cart.reduce((total, item) => total + item.price * item.quantity, 0) * 1000).toLocaleString()}&nbsp;₫</span>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                            <div className="font-semibold">Phí vận chuyển</div>
                            <div>
                                <span>13.000&nbsp;₫</span>
                            </div>
                        </div>
                    </div>
                    <div className="text-sm mb-4">
                        Bằng việc bấm vào nút “Đặt hàng”, tôi đồng ý với
                        <a href="#" target="_blank" className="text-green-600 ml-1">chính sách hoạt động</a>
                        của chúng tôi.
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-lg">Tổng tiền</div>
                        <div className="font-bold text-lg">
                            <span>{(cart.reduce((total, item) => total + item.price * item.quantity, 0) * 1000 + 13000).toLocaleString()}&nbsp;₫</span>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Checkout;


