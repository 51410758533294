import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import About from '../pages/About';
import Login from '../pages/Login';
import ForgotPass from '../pages/ForgotPass';
import Sign_up from '../pages/Sign_up';
import Product from '../pages/Product';
import Checkout from '../pages/Checkout';
import Userinfo from '../pages/Userinfo';
import Logout from '../pages/Logout';
import UserOrders from '../pages/UserOrders';
import DonHangList from '../pages/DonHangList';
import Cuahang from '../pages/Cuahang';
import Loginad from '../admin/adminpages/Loginad';
import Indexad from '../admin/indexad';
import CheckDonHangAd from '../pages/CheckDonHangAd'; // Sửa từ 'form' thành 'from'
import ProductAdmin from '../admin/ProductAdmin'; // Thêm import; sửa từ 'form' thành 'from'

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "",
          element: <Home />
        },
        {
          path: "about",
          element: <About />
        },
        {
          path: "login",
          element: <Login />
        },
        {
          path: "forgotpass",
          element: <ForgotPass />
        },
        {
          path: "sign_up",
          element: <Sign_up />
        },
        {
          path: "products",
          element: <Product />
        },
        {
          path: "checkout",
          element: <Checkout />
        },
        {
          path: "userinfo",
          element: <Userinfo />
        },
        {
          path: "logout",
          element: <Logout />
        },
        {
          path: "user-orders",
          element: <UserOrders />
        },
        {
          path: "DonHangList",
          element: <DonHangList />
        },
        {
          path: "Cuahang",
          element: <Cuahang />
        },
        {
          path: "loginad",
          element: <Loginad />
        },
        {
          path: "indexad",
          element: <Indexad />
          
        },
        {
          path: "checkdonhangad",
          element: <CheckDonHangAd />
          
        },
        {
          path: "productadmin",
          element: <ProductAdmin />
        }
      ]
    }
  ]
);

export default router;