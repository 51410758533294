import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserOrders } from '../redux/orderSlice';

const UserOrders = () => {
    const dispatch = useDispatch();
    const { orders, loading, error } = useSelector((state) => state.orders);

    useEffect(() => {
        dispatch(fetchUserOrders());
    }, [dispatch]);

    return (
        <div>
            <h2>Danh sách đơn hàng của bạn</h2>
            {loading && <p>Đang tải...</p>}
            {error && <p>Đã xảy ra lỗi: {error}</p>}
            <ul>
                {orders.map((order) => (
                    <li key={order.id}>
                        <p>Mã đơn hàng: {order.id}</p>
                        <p>Ngày giao hàng: {new Date(order.delivery_date).toLocaleDateString()}</p>
                        <p>Tổng giá trị: {order.total_amount}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserOrders;