import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import { IoSearchCircleOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { logoutUser } from '../redux/apiRequest';
import { CartContext } from './CartContext'; // Import CartContext

const Header = ({ onCartClick, cart }) => {
  const [showAuthContainer, setShowAuthContainer] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const totalCartItems = cart.reduce((total, item) => total + item.quantity, 0); // Tính tổng số lượng sản phẩm trong giỏ hàng

  useEffect(() => {
    const currentUser = Cookies.get('currentUser');
    setCurrentUser(currentUser ? JSON.parse(currentUser) : null);
  }, []);

  const toggleAuthContainer = () => {
    setShowAuthContainer(!showAuthContainer);
  };

  const toggleSearchForm = () => {
    setShowSearchForm(!showSearchForm);
  };

  const handleLogout = () => {
    logoutUser(null, navigate).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error("Logout failed:", error);
    });
  };

  const myAccount = () => {
    // Xử lý hiển thị thông tin tài khoản tại đây
    console.log('Tài khoản của tôi');
  };

  const orderHistory = () => {
    // Xử lý hiển thị lịch sử đơn hàng tại đây
    console.log('Đơn hàng đã mua');
  };

  return (
    <header className="h-21">
      <div className="header-top"></div>
      <div className="header-middle">
        <div className="h-full container mx-auto flex items-center px-4 justify-between">
          <div>
            <Link to="/">
              <Logo width={170} height={80} classname=' text-4xl' />
            </Link>
          </div>

          <div className="lg:flex w-[600px] max-w-sm items-center justify-between rounded-full search-container">
            <form
              action=""
              className={`form-search w-full ${showSearchForm ? '' : 'hidden'}`}
            >
              <span className="text-lg min-w-[50px] h-10 rounded-l-full bg-gray-200 flex items-center justify-center text-green-700">
                <IoSearchCircleOutline />
              </span>
              <input
                type="text"
                className="form-search-input"
                placeholder="Tìm kiếm sản phẩm..."
                onInput={() => {}}
              />
            </form>
          </div>

          <div className="header-middle-right">
            <ul className="header-middle-right-list">
              <li className="header-middle-right-item dnone open" onClick={() => {}}>
                <div className="cart-icon-menu">
                  <i className=""></i>
                </div>
              </li>
              <li className="header-middle-right-item close" onClick={() => {}}>
                <div className="cart-icon-menu">
                  <i className="fa-light fa-circle-xmark"></i>
                </div>
              </li>
              <div className="text-dndk mr-4 flex items-center">
              
              {currentUser ?  
              
              <li className={`header-middle-right-item dropdown ${showAuthContainer ? 'open' : ''}`}><i className="fa-light fa-user" onClick={toggleAuthContainer}></i> 
                
                <div className="auth-container" onClick={toggleAuthContainer}>
                  
                  <span className="text-dndk">   {currentUser ? `Tài khoản` : <Link to="/login" className="text-dndk text-sm">Đăng nhập | Đăng ký</Link>}</span>
                  <span className="text-tk">
                    {currentUser ? `Hi, ${currentUser.username}!` : <Link to="/login" className="">Tài khoản</Link>}
                    <i className="fa-sharp fa-solid fa-caret-down"></i>
                  </span>
                </div>
                {showAuthContainer && currentUser && (
                  <ul className="header-middle-right-menu">
                    <li>
                      <Link className="text-2xl" to="/Userinfo" href="javascript:;" onClick={myAccount}>
                        <i className="fa-light fa-circle-user"></i> Tài khoản của tôi
                      </Link>
                    </li>
                    <li>
                      <Link className="text-2xl" to="/DonHangList" href="javascript:;" onClick={orderHistory}>
                        <i className="fa-regular fa-bags-shopping"></i> Đơn hàng đã mua
                      </Link>
                    </li>
                    <li className="border">
                      <a id="logout" href="javascript:;" onClick={handleLogout}>
                        <i className="fa-light fa-right-from-bracket"></i> Thoát tài khoản
                      </a>
                    </li>
                  </ul>
                )}
               
              </li>
              : <Link to="/login" className="text-2xl text-green-700"><i className="fa-light fa-user"></i></Link>}
              </div>

              <li className="header-middle-right-item open" onClick={onCartClick}>
                <div className="cart-icon-menu">
                  <i className="fa-light fa-basket-shopping"></i>
                  <span className="count-product-cart">{totalCartItems}</span>
                </div>
                <span>Giỏ hàng</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <style>
        {`
          .auth-options {
            display: flex;
            gap: 1rem;
          }

          .auth-dropdown .flex {
            display: flex;
            align-items: center;
          }

          .auth-dropdown .text-dndk {
            margin-right: 1rem;
          }

          @media (max-width: 845px) {
            .auth-dropdown .text-dndk:last-child {
              display: none;
            }
          }

          .auth-dropdown-list li {
            list-style: none;
            margin-bottom: 0.5rem;
          }

          .auth-links {
            display: flex;
            align-items: center;
          }

          @media (max-width: 600px) {
            .search-container {
              display: none;
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;
