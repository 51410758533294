import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import productsReducer from './productsSlice';
import orderReducer from './orderSlice';
import donHangReducer from './donHangSlice';
import dangkyReducer from './dangkySlice';
import userReducer from './userSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productsReducer,
    order: orderReducer,
    users: userReducer,
    donhang: donHangReducer,
    dangky: dangkyReducer, // Thêm reducer mới vào đây
  },
});

export default configureStore({
    reducer: {
        auth: authReducer,
    },
});




